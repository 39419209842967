<template>
  <panel 
    class="u-main-color"
    @prev="$emit('prev')" 
    @next="$emit('next')" >

    <div class="u-wrapper-panel u-overflow-y-scroll u-flex--sm u-column--sm">
      <router-link v-if="$device.isMobile" :to="'/region/' + route.params.regionName" class="u-border u-self-middle--sm u-bg-red u-radius--bigu-marg-r-sm close u-marg-0--vert">
        <svg class="u-fit-contain u-stroke-white u-fill-transparent article__category__svg" >
          <use xlink:href="#cross"/>
        </svg>
      </router-link>
      <h3 v-if="!$device.isMobile" class="t-h3 u-marg-b-xs">Nos réalisations {{ article.region.title }}</h3>
      <core-drag v-if="!$device.isMobile" :active-index="activeIndex" :children="articlesRef">
        <ul class="info__top__list u-flex u-inline-block u-nowrap u-pad-t-md u-pad-b-lg u-left js-drag u-pad-b-md--sm">
          <li v-for="article in articles" :key="article.id" :ref="setArticleRef" class="info__top__list__item u-inline-block js-drag-item u-align-top u-pad-r-lg u-pad-r-md--sm">
            <router-link draggable="false" @click.stop="null" class="info__top__list__item__link u-relative u-inline-block" :to="'/region/' + route.params.regionName + '/realisation/' + article.slug">
              <span draggable="false" class="t-text--big t-extrabold u-inline-block">{{article.title}}</span>
            </router-link>
          </li>
        </ul>
      </core-drag>
      <div class="u-row u-right u-middle u-marg-t-sm u-marg-t-md--sm">
        <category v-if="article.btCatReal?.[0]" class="article__category u-white" :content="article.btCatReal?.[0]" :clickable="false"/>
        <router-link v-if="!$device.isMobile" :to="'/region/' + route.params.regionName + '/realisation/' + article.slug + '/read'" class="u-border u-radius--big u-pad-x-md u-pad-y-sm u-marg-r-sm article__read_button">
          <svg class="u-fit-contain u-stroke-black u-fill-transparent article__category__svg" >
            <use xlink:href="#glasses"/>
          </svg>
          Mode Lecture
        </router-link>
        <router-link v-if="!$device.isMobile" class="article__back_button" :to="'/region/' + route.params.regionName">
          <svg class="u-fit-contain u-stroke-black u-fill-transparent" >
            <use class="article__back_button__svg" xlink:href="#curved-arrow"/>
          </svg>
        </router-link>
      </div>
      <h1 class="t-h0 article__title u-marg-b-sm--sm">{{ article.title}}</h1>
      <youtube-player :isControls="true" :poster="article.image.length ? article.image[0].url : ''" class="u-full-width u-radius article__illu" v-if="article.youtubeId && !readMode" :id="article.youtubeId" />
      <!-- <vimeo-player class="u-full-width u-radius article__illu" v-else-if="vimeoId" :id="vimeoId" /> -->
      <img v-else-if="article.image?.length" class="u-full-width u-radius article__illu" :src="article.image[0].url" :alt="article.btCatReal[0]?.title">
      <div class="t-text--paragraph t-text--paragraph--small u-marg-b-lg u-marg-b-md--sm" v-html="article.wysiwyg"></div>
      
      <button @click.stop="emit('toggleConseiller')" :class="[$device.isMobile ? 'mail-button--mobile u-flex u-center u-middle' : 'u-radius--bigger', $device.isPortraitDesktop ? 'mail-button--vert' : '']" class="u-z-front u-pad-y-sm u-pad-x-lmd u-bg-red u-color-white u-inline-flex mail-button u-middle u-between u-white">
        <svg :class="{'u-marg-r-sm': !$device.isMobile}" class="u-fit-contain u-fill-white" >
          <use xlink:href="#write-picto"/>
        </svg>
        <span v-if="!$device.isMobile">
          Contacter votre direction régionale
        </span>
      </button>
      
    </div>
    <router-view v-slot="{ Component }">
      <transition name="t-fader">
        <component :is="Component">
          <div v-if="article.btCatReal?.[0]" class="u-row u-right u-middle">
            <category class="article__category" :content="article.btCatReal?.[0]" :clickable="false"/>
          </div>
          <h1 class="t-h1--mega article__title">{{ article.title}}</h1>
          <youtube-player :isControls="true" :poster="article.image.length ? article.image[0].url : ''" class="u-full-width u-radius article__illu oui" v-if="article.youtubeId" :id="article.youtubeId" suffix="full" />
          <!-- <vimeo-player class="u-full-width u-radius article__illu" v-else-if="vimeoId" :id="vimeoId" /> -->
          <img v-else-if="article.image?.length" class="u-full-width u-radius article__illu" :src="article.image[0].url" :alt="article.btCatReal?.[0].title">
          <div class="t-text--paragraph article__content" v-html="article.wysiwyg"></div>
          <div class="other-articles u-marg-l-xs u-pad-l-sm u-flex u-overflow-x-scroll--md">
            <article-card 
              :class="'panel__card--third u-marg-b-md u-marg-r-md'"
              v-for="_article in articles.filter(a => a.id !== article.id).slice(0, 3)" 
              :key="_article.id" 
              :article="_article"/>
          </div>
          <div class="overlay"></div>
        </component>
      </transition>
    </router-view>
  </panel>
  <router-link :to="'/region/' + route.params.regionName" @click.stop="null" :class="{'map-button--mobile':$device.isMobile, 'map-button--vert': $device.isPortraitDesktop}" class="u-z-front u-red u-flex u-center u-middle u-column u-bg-white u-round map-button t-bold u-absolute u-red u-shadow">
    <svg class="u-fill-red" >
      <use xlink:href="#map-picto "/>
    </svg>
    <span class="u-red" v-if="!$device.isMobile">
      Region
    </span>
  </router-link>
</template>

<script setup>
import ArticleCard from '@/components/ListPanel/ArticleCard';
import Category from '@/components/ListPanel/Filters/Category';
import Panel from '@/components/Panel';
import CoreDrag from '@/components/RealisationPanel/CoreDrag';
import gsap from 'gsap';
import { FitTo } from 'shimmer';
import { Vector3 } from 'three';
import { computed, inject, onBeforeUnmount, onBeforeUpdate, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import france from '../../../webGL/objects/France';
import { webGL } from '../../../webGL/WebGL';
import VimeoPlayer from '@/components/common/players/VimeoPlayer';
import YoutubePlayer from '@/components/common/players/YoutubePlayer';
import { cartesianToPolar } from '../../../utils/Maths';
import { gui } from '../../../utils/GUI2';

const route = useRoute()
const store = useStore()
const device = inject('device')

const emit = defineEmits(['toggleConseiller'])

const articlesRef = ref([])

const article = computed(() => {
  // TODO: refacto to only search in articles with right category
  return store.getters['data/activeRealisationEntries'].find(entry => entry.slug === route.params.realisationName)
})

const articles = computed(() => {
  return store.getters['data/activeLocalRealisationEntries']
})

const activeIndex = computed(() => {
  return articles.value.findIndex(article => article.slug === route.params.realisationName)
})

const readMode = computed(() => {
  return route.path.endsWith('/read')
})

const setArticleRef = (el) => {
  if (el) {
    articlesRef.value.push(el)
  }
}

let projectDeco = france.getProjectByEntryTitle(article.value.title)

const zoomProjectOptions = {
  padding: device.isMobile.value ? { top: 2, right: 2, bottom: 2, left: 2 } : { top: 0.635, right: 2.935, bottom: 3.355, left: 1.82 },
  vector: new Vector3(-0.5, 1, 1)
}

const placeCamera = () => {

    store.commit('global/setZooming', true)

    france.hideAllProjectHalos()

    projectDeco = france.getProjectByEntryTitle(article.value.title)

    const { position, look } = FitTo.fit(projectDeco.boundingBox, zoomProjectOptions.padding, { vector: zoomProjectOptions.vector }, 'contain')

    let regionInstance = france.getRegionByEntryName(article.value.region.title)

    const center = new Vector3()
    projectDeco.boundingBox.getCenter(center)
    
    gsap.to(webGL.camera.position, {
      x: position.x,
      y: position.y,
      z: position.z,
      duration: 2,
      ease: 'power4.inOut',
      onComplete: () => {
        webGL.controls.minDistance = Math.min(2.32, webGL.camera.position.distanceTo(projectDeco.boundingBox.max))

        if (regionInstance.isDomTom) {
          webGL.controls.minDistance = 7.32
        }

        store.commit('global/setZooming', false)
      }
    })
    gsap.to(webGL.camera.look, {
      x: look.x,
      y: look.y,
      z: look.z,
      duration: 2,
      ease: 'power4.inOut'
    })
    gsap.to(webGL.controls?.target, {
      x: look.x,
      y: look.y,
      z: look.z,
      duration: 2,
      ease: 'power4.inOut'
    })
    
    projectDeco.showHalo()

    webGL.regionPan.isActive = true

    webGL.controls.enablePan = true

}

const folder = gui?.addFolder('Zoom Project padding').onChange(() => placeCamera())
folder?.add(zoomProjectOptions.padding, 'top', -5, 5)
folder?.add(zoomProjectOptions.padding, 'right', 0, 5)
folder?.add(zoomProjectOptions.padding, 'bottom', 0, 15)
folder?.add(zoomProjectOptions.padding, 'left', 0, 5)
folder?.close()
const folderVector = gui?.addFolder('Zoom project vector').onChange(() => placeCamera())
folderVector?.add(zoomProjectOptions.vector, 'x', -5, 5)
folderVector?.add(zoomProjectOptions.vector, 'y', -5, 5)
folderVector?.add(zoomProjectOptions.vector, 'z', -5, 5)
folderVector?.close()

placeCamera()
onBeforeUpdate(() => {
  placeCamera()
})
onBeforeUnmount(() => {
  projectDeco = null
  folder?.destroy()
  folderVector?.destroy()
})
</script>

<style scoped lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/easings'
@import '~@/styles/settings/mixins/mq'
@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

.panel
  position absolute
  right 0
  top 0
  height 100%
  width clamp(300px, 30%, 600px)

  +mq($until: 'tablet')
    width 100%
    top auto
    bottom 0
    height calc( 100% - 100px )

.close
  display: inline-flex;
  margin: auto;
  align-self: center;
  text-align: center;
  height: 56px !important;
  width: 50px;
  justify-content: center;
  align-items: center;
  border-radius: 70px;
  min-height 50px;

  svg
    margin 0
.article
  &__title
    margin-top 35px
    margin-bottom 25px
  &__illu
    min-height 150px
    max-height 500px
    object-fit cover
    margin-bottom 25px

  &__category
    margin-right auto

    &__svg
      width 20px !important
      height 18px !important
      fill currentColor
      margin-right 5px

  &__read_button
    border solid #C4C4C4 1px

  &__back_button
    width 28px
    height 26px

+mq($until: 'tablet')
  .article
    &__title
      margin-top 15px
      margin-bottom 10px
      

+mq($from: 'desktop')
  .article
    &__content
      column-count 2
      margin-bottom 90px

.info
  &__top__list__item
    white-space normal
    max-width 15vw
    
    @media ( max-width: $mq-breakpoints.tablet )
      max-width 33vw
      
    @media ( max-width: $mq-breakpoints.mobile )
      max-width 50vw

    &__link
      opacity .4
      transition opacity .3s linear

      &:after
        position absolute
        content ''
        
        bottom -15px
        left 0
        
        height 4px
        width 100%
        
        border-radius 5px
        
        background red
        transform scaleX(0) translateZ(0)
        transform-origin 0 0
        transition transform .6s ease-out-quint
      
      &.router-link-active
        opacity 1

        &:after
          transform scaleX(1) translateZ(0)

.mail-button
  bottom 34px
  left 150px
  padding-bottom 15px
  padding-top 15px
  svg
    width 30px
    height 30px
  &--mobile
    top 20px
    right 20px
    border-radius 50px
    bottom initial
    left initial
    height 60px
    width 60px
    z-index 500 !important
    padding 20px !important
.map-button
  bottom 20px
  left 20px
  width 80px
  height 80px
  svg
    width 40px
    height 40px
  &--mobile
    top 20px
    left 20px
    bottom initial
    right initial
    z-index 500 !important
  &--vert
    width 120px
    height 120px
    svg
      width 60px
      height 60px
      margin auto

.overlay
  position absolute
  top 0
  left 0
  right 0
  bottom 0
  background-color rgba(0, 0, 0, .5)
  z-index 1
  display none
  pointer-events none

.panel
  position absolute
  right 0
  top 0
  height 100%
  width 100% !important
  &__card
    &--third
      width calc(94% / 3)
      margin-bottom 20px !important
      margin-right 20px !important
      +mq($until: 'tablet')
        width 94%
</style>