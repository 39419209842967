<template>
  <div class="map">
    <button
      @click.stop="homeView"
      class="u-absolute u-top-0 u-left-0 u-right-0 u-bottom-0 u-z-front logo"
    >
      <img src="/images/bdtLogo.png" alt="Banque des territoires">
    </button>

    <h1 class="map-title u-relative u-row u-center u-uppercase t-h1 u-marg-t-xxl--sm t-montserrat">
      Nos réalisations par territoire
    </h1>

    <router-link :to="isListingView ? '/' : '/listing'" class="burger-wrapper u-absolute u-row u-middle u-center">
      <svg class="u-stroke-white" >
        <use v-if="isListingView" xlink:href="#cross"/>
        <use v-else xlink:href="#burger"/>
      </svg>
    </router-link>

    <div class="projects">
      <project-marker
        v-for="(entry, index) in realisationEntries"
        :key="entry.id + 'project'"
        :args="{ index, ...entry }"
      />
    </div>

    <transition name="t-fader">
      <realisation-categories @close="toggleFilters" v-if="(!$device.isMobile || filtersOnDisplay)"/>
    </transition>

    <div class="regions-if">
      <region-obj @select="selectRegion" v-for="region in regions" :key="region.id" :regionEntry="region" />  
    </div>

    <div class="domtom u-absolute u-z-front u-flex">
      <!-- <button @click.stop="() => loadScene('map', false)">France Métrolpolitaine</button> -->
      <button class="t-text--med u-uppercase u-flex u-column u-middle u-marg-x-md u-marg-x-sm--sm" @click.stop="() => loadScene('pacifique', true)">
        <img class="u-round u-marg-b-smd u-border-aqua u-pad-md u-pad-xs--sm u-middle domtom__button" src="/images/pacifique.png" alt="Pacifique">
        Pacifique
      </button>
      <button class="t-text--med u-uppercase u-flex u-column u-middle u-marg-x-md u-marg-x-sm--sm" @click.stop="() => loadScene('antilles', true)">
        <img class="u-round u-marg-b-smd u-border-aqua u-pad-md u-pad-xs--sm u-middle domtom__button" src="/images/antilles.png" alt="Antilles">
        Antilles
      </button>
      <button class="t-text--med u-uppercase u-flex u-column u-middle u-marg-x-md u-marg-x-sm--sm" @click.stop="() => loadScene('indien', true)">
        <img class="u-round u-marg-b-smd u-border-aqua u-pad-md u-pad-xs--sm u-middle domtom__button" src="/images/indien.png" alt="Indien">
        Indien
      </button>
    </div>
    <button @click.stop="toggleFilters" class="u-white u-pad-sm u-round u-bg-red u-absolute u-z-front filters-toggle" v-if="$device.isMobile">
      <svg class="u-fill-white" >
        <use xlink:href="#filters"/>
      </svg>
    </button>
    <router-link @click.stop="selectInitialRegion" class="u-white t-button t-bold u-pad-x-lg--vert u-pad-y-md--vert u-pad-x-md u-pad-y-sm t-montserrat u-uppercase u-radius u-bg-red u-absolute u-z-front enter-button" to="/region/bretagne"  v-if="$device.isMobile">
      Découvrir les territoires
    </router-link>
    
    <router-view 
      v-slot="{ Component }" >
      <transition name="t-fader">
        <component
          @homeView="homeView"
          @nextRegion="nextRegion"
          @prevRegion="prevRegion" 
          :isFullListingView="true"
          :data="{ categories }"
          :is="Component" />
          <!-- last three used for full list panel -->
      </transition>
    </router-view>
  </div>
</template>

<script>
import ProjectMarker from "@/components/3d/ProjectMarker";
import NameBubble from '@/components/NameBubble';
import RealisationCategories from '@/components/RealisationCategories';
import RegionObj from "@/components/RegionObj";
import france from "@/webGL/objects/France";
import { webGL } from "@/webGL/WebGL";
import gsap from "gsap";
import { FitTo } from "shimmer";
import { Vector3 } from "three";
import { computed, inject, onBeforeUnmount, onMounted, ref } from 'vue';
import { onBeforeRouteUpdate, useRoute } from 'vue-router';
import { useStore } from 'vuex';
import { cartesianToPolar } from "../utils/Maths";
import { gui } from "../utils/GUI2";

export default {
  components: {
    ProjectMarker,
    NameBubble,
    RealisationCategories,
    RegionObj
  },
  props: {
      controls: {
        type: Object,
        default: () => {}
      },
    },
  emits: ["homeView", "select", "changeZone"],
  setup(props, { emit }) {
    const route = useRoute()
    const store = useStore()
    const device = inject('device')
    
    const filtersOnDisplay = ref(false)
    
    const isHome = computed(() => route.path === "/")
    const isListingView = computed(() => route.path === '/listing')
    
    const regions = computed(() => {
      return store.getters['data/regions']
    })
    
    const activeLocalRealisationEntries = computed(() => {
      return store.getters['data/activeLocalRealisationEntries']
    })
    
    const realisationEntries = computed(() => {
      return store.getters['data/activeRealisationEntries']
    })
    
    const categories = computed(() => {
      return store.getters['data/realisationCategories']
    })
    
    const hoveredProject = computed(() => {
      return store.state.global.mouseOverProject
    })
    
    const toggleFilters = () => filtersOnDisplay.value = !filtersOnDisplay.value
    
    const selectRegion = (regionInstance) => {
      emit('select', regionInstance)
    }
    
    const selectInitialRegion = () => {
      selectRegion(france.regionInstances[0])
    }
    
    const homeView = () => {
      emit('homeView')
    }
    
    const loadScene = (scene, isMulti) => {
      emit('changeZone', { zoneName: scene, isMulti })
    }

    const zoomMacroOptions = { 
      padding: device.isMobile.value 
        ? {top: 0, right: 0, bottom: 1.5, left: 0}
        : device.isWide.value
          ? {top: -3., right: 1.8, bottom: 9.5, left: 1.4}
          : {top: 0., right: 1.8, bottom: 11.5, left: 1.4},
      vector: device.isMobile.value
        ? new Vector3(0.8, 2.5, 2)
        : new Vector3(0.3, 2.5, 2),
     }

    const placeCamera = () => {
      store.commit('global/setZooming', true)
      webGL.regionPan.blockOob = false

      const { position, look } = FitTo.fit(france.mapBbox, zoomMacroOptions.padding, { vector: zoomMacroOptions.vector }, 'contain')
      position.z += 2
      look.z += 2
      gsap.to(webGL.camera.position, {
        x: position.x,
        y: position.y,
        z: position.z,
        duration: 2,
        ease: 'power4.inOut',
        onComplete: () => {
          webGL.regionPan.blockOob = true
          setTimeout(() => {
            store.commit('global/setZooming', false)
          }, 500)
        },
      })
      webGL.controls.target.copy(webGL.camera.look)
      gsap.to(webGL.camera.look, {
        x: look.x,
        y: look.y,
        z: look.z,
        duration: 2,
        ease: 'power4.inOut'
      })
      gsap.to(webGL.controls?.target, {
        x: look.x,
        y: look.y,
        z: look.z,
        duration: 2,
        ease: 'power4.inOut'
      })

      webGL.regionPan.isActive = false
      webGL.controls.enablePan = false
      webGL.regionPan.regionBox = null
      webGL.controls.maxDistance = 145
    }

    // debug
    const folder = gui?.addFolder('Zoom Macro padding').onChange(() => placeCamera())
    folder?.add(zoomMacroOptions.padding, 'top', -5, 15)
    folder?.add(zoomMacroOptions.padding, 'right', -5, 15)
    folder?.add(zoomMacroOptions.padding, 'bottom', -5, 15)
    folder?.add(zoomMacroOptions.padding, 'left', -5, 15)
    folder?.close()
    const folderVector = gui?.addFolder('Zoom Macro vector').onChange(() => placeCamera())
    folderVector?.add(zoomMacroOptions.vector, 'x', -5, 5)
    folderVector?.add(zoomMacroOptions.vector, 'y', -5, 5)
    folderVector?.add(zoomMacroOptions.vector, 'z', -5, 5)
    folderVector?.close()

    gui?.close()


    onMounted(() => {
      if (france.isLoaded) {
        placeCamera()
        return
      }
      france
        .loadObject({ controls: props.controls })
        .then(() => {
          france.regionsLoaded = store.dispatch("data/categories")
          france.regionsLoaded.then(() => france.bindRegions(store.getters['data/regions']))
          store.dispatch("data/entries")
          placeCamera()
        });
      webGL.scene.add(france);
    
    })

    onBeforeRouteUpdate((to) => {
      if (to.name === 'Map') {
        placeCamera()
      }
    })

    onBeforeUnmount(() => {
      folder?.destroy()
      folderVector?.destroy()
    })

    return {
      loadScene,
      homeView,
      selectInitialRegion,

      toggleFilters,
      hoveredProject,
      categories,isHome,
      isListingView,
      realisationEntries,
      route,
      activeLocalRealisationEntries,
      regions,
      filtersOnDisplay,
      selectRegion
    }
  }
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'
@import '~@/styles/settings/mixins/mq'


+mq($until: 'desktop')
  .map-title
    max-width 70%
    margin: auto
    letter-spacing 3px
+mq($from: 'desktop')
  .map-title
    letter-spacing: 0.4em
    margin-top: 70px

.enter-button
  bottom 10px
  right 10px
  color white !important
  +mq($from: 'desktop', $vertical: true)
    font-size: 1.5em
    border-radius: 10px
    bottom 20px
    right 20px

.filters-toggle
  bottom 10px
  left 10px
  max-height: 18px;
  box-sizing: content-box;
  svg
    width 18px
    height 18px

.domtom
  bottom 65px
  left: 55px
  &__button
    height: 90px;
    width: 90px;
    object-fit: contain;
    border-width: 2px
    border-style: solid

.burger-wrapper
  background-color #CF332C
  top 50px
  right 50px
  width 73px
  height 73px
  border-radius 50%
  color white
  line-height 5px
  cursor pointer
  z-index 1000
  svg
    width 40%
    height: 70%;
  +mq($until: 'desktop')
    width 50px
    height 50px
    top 15px
    right 20px
    svg
      width: 32%

.logo
  top 65px
  left 70px
  z-index: 1000 !important

+mq($until: 'desktop')
  .domtom
    width: 75%;
    left: 12.5%;
    bottom: 15%;
    button
      font-size 8px !important
      font-weight 400 !important
    &__button
      height: 48px;
      width: 48px;
      border-width: 1px

  .logo
    top: 35px  
    left: 20px
    z-index: 1000 !important
    img
      width: 110px

+mq($from: 'desktop', $vertical: true)
  .domtom
    bottom: 10%;
  .filters-toggle
    bottom: 10px
    left: 10px
    max-height 28px
    svg
      width 28px
      height 28px
  .burger-wrapper
    top 75px
    right 75px
    width 90px
    height 90px

  .map-title
    font-size 26px !important
    position: relative
    left 30px

  .logo
    top: 95px
</style>