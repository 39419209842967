import { createApp, mixin } from 'vue'
import { createRouter } from 'vue-router'

import * as Sentry from "@sentry/vue";

import { createI18n } from 'vue-i18n'

// import * as TastyBurgerButton from 'vue-tasty-burgers';

import { store } from '@/store'
import { router } from './router'

import Local from '@/plugins/Local'
import Device from '@/plugins/Device'
import Hub from '@/plugins/Hub'
import Offset from '@/plugins/Offset'
import Dom3d from '@/plugins/Dom3d'

import messages from '@/locales'


import extendedComponent from '@/mixins/extendedComponent'

import App from './App.vue'

import { emitter } from '@/utils/emitter'

class VueArtwork {
  constructor () {
    this.app = createApp(App)

    // router
    this.router = createRouter(router)
    this.router.beforeEach((to, from, next) => {
      if (from.query.debug) {
        to.query.debug = from.query.debug
      }
      next()
    })
    this.app.use(this.router)

    // i18n
    this.i18n = createI18n({
      locale: 'en',
      messages
    })
    this.app.use(this.i18n)

    // VueX
    this.app.use(store)

    // Mixin
    this.app.mixin(extendedComponent)

    // Handmade Plugins
    this.app.use(Local)
    this.app.use(Device)
    this.app.use(Hub)
    this.app.use(Offset)
    this.app.use(Dom3d)

    // Plugin injection for composition api components
    this.app.provide('hub', emitter)

    // // Burger Button
    // this.app.use(TastyBurgerButton)

    this.app.mount('#app')

    this.app.config.warnHandler = function (msg, vm, trace) {
      return null
    }
  }
}

new VueArtwork()

Sentry.init({
  dsn: "https://18760e2c250bf6eae20f27f630ec20b3@o1141529.ingest.sentry.io/4506659601448960",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", /^https:\/\/banquedesterritoires\.brig\.ht/],
    }),
    new Sentry.Replay({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})