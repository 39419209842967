
<template>
  <transition name="t-simple-fader">
    <name-bubble2d
      v-if="!$device.isTablet && args.object3d && ($route.meta?.isProject) && isHover"
      :name="args.title"
      :size="12"
      :isVisible="isHover"
      />
  </transition>
  <transition name="t-fader">
    <name-bubble
      ref="bubble"
      v-if="!$device.isTablet && args.object3d && (!$route.meta?.isProject) && isHover && !$store.state.global.isDragEnd"
      lookAt="{ x: cameraPos.x, y: cameraPos.y, z: cameraPos.z }" 
      :name="args.title"
      :position="bubblePosition"
      :size="isDomTom ? 20 : 12"
      :maxScale="isDomTom ? 250 : 140"
      />
  </transition>
</template>

<script>
import { webGL } from '@/webGL/WebGL'
import ProjectObj from '@/webGL/objects/Project'
import NameBubble from '@/components/NameBubble'
import NameBubble2d from '@/components/NameBubble2d'
import france from '../../webGL/objects/France'
import DecoObjectLazy from '../../webGL/objects/DecoObjectLazy'
import { Vector3, Box3 } from 'three'
import { gui } from '../../utils/GUI2'
import map from '@/webGL/objects/France'
import { toScreenPosition } from 'shimmer'

const bubbleTranslate = { x: -0, y: -3, z: 0 }
const folder = gui?.addFolder('Project Names')
folder?.add(bubbleTranslate, 'x', -100, 100).step(0.1)
folder?.add(bubbleTranslate, 'y', -100, 100).step(0.1)
folder?.add(bubbleTranslate, 'z', -100, 100).step(0.1)
folder?.close()

export default {
  props: {
    args: {
      type: Object,
      default: {}
    },
    isDomTom: {
      type: Boolean,
      default: false
    },
  },

  components: {
    NameBubble,
    NameBubble2d,
  },

  data(){
    return {
      isHover: false,
      bubblePosition: new Vector3(0, 0, 0),
    }
  },

  emits: ['loadObject'],

  mounted() {
    this.init()
  },

  methods: {
    init() {

      if (this.$props.args.object3d) {
        this.projectObject = new ProjectObj(this.$props.args, {
          onEnter: this.onEnter,
          onOut: this.onOut,
          onClick: this.onClick
        }, this.isDomTom)


        this.projectObject.ready.then(() => {

          setTimeout(async () => {
            if (this.$route.meta?.isProject) return

            try {
              
              const bbox = new Box3().setFromObject(this.projectObject.mesh)
              this.bubblePosition = bbox.min

              const screenPos = toScreenPosition(this.bubblePosition, webGL.camera, this.$device.width, this.$device.height)
              if (!this.$device.isMobile && screenPos.x > (window.innerWidth-850)) {
                this.bubblePosition.x -= 2.9 * this.bubblePosition.x/10
                this.bubblePosition.y += 0.9 * this.bubblePosition.x/10
              } else {
                this.bubblePosition.x -= 1
              }


            } catch (error) {
              console.error(error)
            }
          }, 500);
        })
      } else {
        this.projectObject = new DecoObjectLazy(this.$props.args)
      }
      webGL.scene.add(this.projectObject)
      france.addProjectDeco(this.projectObject)

    },
    correctedBubblePosition(coords) {
      const pos = this.projectObject.position
      pos.x -= 0.5
      pos.y += 2
    
      return pos
    },
    onEnter () {
      this.isHover = true
      document.body.style.cursor = 'pointer'
    },
    onOut () {
      this.isHover = false
      console.log(webGL.controls.noPan)
      webGL.controls.enablePan ? document.body.style.cursor = 'grab' : document.body.style.cursor = 'initial'
    },
    onClick() {
      this.$router.push('/region/' + this.$route.params.regionName + '/realisation/' + this.args.slug)
    }
  },

  beforeUnmount () {
    const clear = (obj) => {
      obj?.traverse(child => {
        if (child.geometry) child.geometry.dispose(); child.geometry = null
        if (child.material) {
          if (Array.isArray(child.material)) {
            child.material.forEach(material => {
              material.aoMap?.dispose()
              material.alphaMap?.dispose()
              material.bumpMap?.dispose()
              material.displacementMap?.dispose()
              material.normalMap?.dispose()
              material.emissiveMap?.dispose()
              material.envMap?.dispose()
              material.lightMap?.dispose()
              material.metalnessMap?.dispose()
              material.roughnessMap?.dispose()
              material.map?.dispose()
              material.map = null
              material.needsUpdate = true
              material.dispose()
              material = null
            })
          } else {
            child.material.aoMap?.dispose()
            child.material.alphaMap?.dispose()
            child.material.bumpMap?.dispose()
            child.material.displacementMap?.dispose()
            child.material.normalMap?.dispose()
            child.material.emissiveMap?.dispose()
            child.material.envMap?.dispose()
            child.material.lightMap?.dispose()
            child.material.metalnessMap?.dispose()
            child.material.roughnessMap?.dispose()
            child.material.map?.dispose()
            child.material.map = null
            child.material.needsUpdate = true
            child.material.dispose()
            // child.material = null
          }
        }
      })
    }

    this.projectObject.visible = false
    if (this.projectObject.beforeDestroy) this.projectObject.beforeDestroy()
    setTimeout(() => {
      clear(this.projectObject.halo)
      clear(this.projectObject.mesh)
      clear(this.projectObject)
      if (this.projectObject.destroy) this.projectObject.destroy()
      this.projectObject.remove(this.projectObject.mesh)
      webGL.scene.remove(this.projectObject)
      france.removeProjectDeco(this.projectObject)
      // this.projectObject.mesh = null
      // this.projectObject = null
    }, 5500)
  }
}
</script>