import helvetiker from '@/assets/fonts/helvetiker_regular.typeface.json'
import map from '@/webGL/objects/France'
import gsap from 'gsap'
import { Component, Loader } from 'shimmer'
import { Color, FontLoader, Mesh, MeshBasicMaterial, TextGeometry } from 'three'
import { degToRad } from 'three/src/math/MathUtils'
import { gui } from '../../utils/GUI2'
import StandardizedMesh from '../../utils/Standardizer'

const fontLoader = new FontLoader()

const folder = gui?.addFolder('Objects correction colors')
const emissiveColor = new Color(0x000000)
const mapColor = new Color(0xffffff)
const adjustColor = new Color(0x010100)
// const emissiveColor = new Color(0x050509)
// const mapColor = new Color(0xaaccff)
const colors = { emissiveColor, mapColor, adjustColor }
folder?.addColor(colors, 'emissiveColor')
folder?.addColor(colors, 'mapColor')
folder?.addColor(colors, 'adjustColor')
export default class DecoObjectLazy extends Component {
  constructor ({ object3d, 
    geolocalisation, 
    degree, 
    scale, 
    title, 
    isRegionDeco,
    id, 
    fontSize, 
    slug, 
    router, 
    atlasMode, 
     }) {
    super('Project')
    
    this.entryId = id
    this.entryName = slug
    this.object3d = object3d
    this.geolocalisation = geolocalisation
    this.degree = degree
    this._scale = scale
    this.title = title
    this.fontSize = fontSize
    this.router = router

    this.name = title

    this.position.set(0, -2, 0)

    this.ready = (async() => {
      if (undefined !== this.object3d) {
        if (!this.object3d[0]?.url) return
          this.mesh = await this.setupMesh(this.object3d , atlasMode, this.degree)
          await this.setPosition()
          this.addTransform(this.mesh, this.degree, this._scale)
      }
      else {
        // setTimeout(() => {
          this.mesh = this.setupText(this.title)
          await this.setPosition()
          this.addTransform(this.mesh, undefined, this.fontSize)
        // }, 350);
      }

      if (isRegionDeco) map.markers.push(this)
      
      this.add(this.mesh)
    })()
  }

  async setupMesh(model, atlasMode = false) {
    const object = await Loader.load(model[0].url)
    
    const normalized = new StandardizedMesh(object)
    
    // convert to basic material as textures are baked
    // normalized.traverse(child => {
    //   if (child.type === 'Mesh') {
    //     const newMat = new MeshBasicMaterial()
    //     newMat.copy(child.material)
    //     child.material.dispose()
    //     child.material = newMat
    //     child.matrixAutoUpdate = false
    //   }
    // })

    normalized.scale.x *= 5
    normalized.scale.y *= 5
    normalized.scale.z *= 5

    const mat = normalized.children[0].children[0].children[0]?.material
    
    if (!mat) return normalized
    
    // bind controls to material
    mat.emissive = emissiveColor
    mat.color = mapColor
    folder?.onChange(() => mat.needsUpdate = true)
    mat.onBeforeCompile = (shader) => {
      shader.uniforms.adjustColor = { value: adjustColor };

      shader.fragmentShader = 'uniform vec3 adjustColor;\n' + shader.fragmentShader;

      shader.fragmentShader = shader.fragmentShader.replace(
        '#include <map_fragment>',
        `
        #include <map_fragment>
        vec4 sampledDiffuseColor = texture2D( map, vUv );
        // vec4 diffuseColor = vec4( diffuse, opacity );
        if ( sampledDiffuseColor.x > .65 ) {
          // diffuseColor.x -= .2;
          // diffuseColor.z += .02;
          // diffuseColor.y -= .05;
          diffuseColor.x -= adjustColor.x;
          diffuseColor.z += adjustColor.y;
          diffuseColor.y -= adjustColor.z;
        };
        `
      )
    }

    return normalized
  }

  setupText(title) {
    const font = fontLoader.parse(helvetiker)
    const geo = new TextGeometry(title, {
      font, 
      size: 0.5,
      height: 0.05,
    })
    const mat = new MeshBasicMaterial({ color: 0x0a0a0a, metalness: 0.2, roughness: 0.8 })
    const text = new Mesh(geo, mat)
    text.position.y += 0.15
    
    text.rotation.x -= Math.PI / 2

    return text
  }

  addTransform(mesh, degree, scale) {

    if (undefined !== degree && null !== degree) {
      mesh.rotation.y = degToRad(degree)
    }

    if (undefined !== scale && null !== scale) {
      scale /= 100
      mesh.scale.x *= scale
      mesh.scale.y *= scale
      mesh.scale.z *= scale
    }
    this.updateMatrixWorld()
  }

  async setPosition(isText = false) {
    this.matrixAutoUpdate = true
    this.mesh.matrixAutoUpdate = true
    if (undefined !== this.object3d){
      this.mesh.position.copy(await map.coordsToPosition(this.geolocalisation))
    }
    else
      this.mesh.position.copy(await map.coordsToPosition(this.geolocalisation, this.mesh.position.y))

    if (undefined !== this.object3d) {
      gsap.to(this.position, {
        y: 0,
        duration: 0.5,
        ease: 'power3.inOut',
        onComplete: () => {
          this.matrixAutoUpdate = false
          this.mesh.matrixAutoUpdate = false
          if (this.mesh.children[0])this.mesh.children[0].matrixAutoUpdate = false
        }
      })
    }
    else {
      setTimeout(() => {
        gsap.to(this.position, {
          y: 0,
          duration: 0.5,
          ease: 'power3.inOut',
          onComplete: () => {
            this.matrixAutoUpdate = false
            this.mesh.matrixAutoUpdate = false
            if (this.mesh.children[0])this.mesh.children[0].matrixAutoUpdate = false
            setTimeout(() => {
              this.position.y = 0
              this.updateMatrixWorld()
              this.updateMatrix()
            }, 200);
          }
        })
      }, 200);
    }
  }
}