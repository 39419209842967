export default function () {
    let idleTime = 0

    setInterval(() => {
      idleTime = idleTime + 1
      if (idleTime > 150) { 
        if (window.location.pathname !== '/') {
          window.location.assign(window.location.origin)
        }
        else {
          window.location.reload()
        }
      }
    }, 1000)

    window.addEventListener('pointermove', e => idleTime = 0)
    window.addEventListener('pointerdown', e => idleTime = 0)
    window.addEventListener('keypress', e => idleTime = 0)
}