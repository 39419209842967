<template>
  <div :class="[$device.isMobile ? 'mobile u-white' : 'u-radius--bigger', ]" class="region-slider t-montserrat u-relative u-z-front u-row u-center u-middle t-circular t-bold u-uppercase u-marg-t-md u-marg-t-0--sm u-marg-b-md--sm">
    <button @click.stop="$emit('prev')" class="region-slider__button u-flex u-middle">
      <svg :class="[ $device.isMobile ? 'u-stroke-white' : 'u-stroke-black' ]" class="u-fit-contain u-fill-transparent" >
        <use xlink:href="#simple-arrow-left"/>
      </svg>
    </button>
    <span class="u-marg-x-md region-slider__title"> {{ $store.state.global.currentRegion?.title }} </span>
    <button @click.stop="$emit('next')" class="region-slider__button u-flex u-middle">
      <svg :class="[ $device.isMobile ? 'u-stroke-white' : 'u-stroke-black' ]" class="u-fit-contain u-fill-transparent" >
        <use xlink:href="#simple-arrow-right"/>
      </svg>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    currentRegionName: {
      type: String,
      required: true
    },
  },
  emits: ['prev', 'next']
}
</script>

<style lang="stylus">
@import '~@/styles/settings/variables'

.region-slider
  margin-top: 60px !important
  padding 15px 20px
  background: rgba(255, 255, 255, 0.6);
  backdrop-filter: blur(30px);
  width max-content
  margin-left auto
  margin-right auto
  font-size 18px
  &__button svg
    max-height: 18px
    max-width: 25px
    fill: none
  &.mobile
    background-filter none
    background-color $colors.red
    width: 120%;
    left: -10%;
    padding-top: 50px;
    .region-slider__title
      max-width 50%
      font-size 15px
</style>